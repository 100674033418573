<template>
    <div class="Awarp">
        <dashCard class="photovoltaicBox11" :operateWidth="operateWidth" :isLoading="isLoading" :gridOption="gridOption" @changeScreen="changeScreen">
            <template slot="title">{{commonLangHandler('chuneng3_realtimeDataPCS','储能PCS实时数据', getZEdata)}}</template>
            <template slot="aside">
              <a-select v-model="searchObj['ITEM']" style="width: 220px" @change="handleChange" :getPopupContainer="triggerNode => triggerNode.parentNode">
                <a-select-option v-for="(item,key) in optionsData" :key="key" :value="item.value">
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </template>
          <div class="content-warp">
            <div class="content-1st">
              <div class="product-pre1">
                <div>
                  <img style="width: 250px;" src="@/assets/imgs/echarts/储能PCS实时数据.jpg"/>
                </div>
              </div>
              <div class="content-1st-right"> 
                <div class="content-1st-right-row">
                  <div>
                    <font>{{commonLangHandler('information_status','运行状态', getZEdata)}}</font>
                    <span class="color1">{{ topData.运行状态}}</span>
                  </div>
                  <div>
                    <font>{{commonLangHandler('chuneng3_mode','工作模式', getZEdata)}}</font>
                    <span>{{ topData.工作模式 }}</span>
                  </div>
                </div>
                <div class="content-1st-right-row">
                  <div style="word-break: break-all;">
                    <font>{{commonLangHandler('chuneng3_temp','设备温度', getZEdata)}}</font>
                    <span class="color2">{{topData.设备温度+ (topData.设备温度?'℃':'')}}</span>
                  </div>
                  <div style="word-break: break-all;">
                    <font>{{commonLangHandler('chuneng3_efficiency','工作效率', getZEdata)}}</font>
                    <span>{{topData.工作效率+ (topData.工作效率?'%':'')}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="content-2nd">
              <div class="content-2nd-list">
                <div style="margin-left: 8px;">{{commonLangHandler('chuneng3_alternate','交流电', getZEdata)}}</div>
                <div class="content-2nd-list-item">
                  <span class="list-item-label">{{commonLangHandler('chuneng3_active','有功(kW)', getZEdata)}}</span>
                  <div class="list-item-progress" style="width: 118px">
                    <span :class="'status'+ '1'" :style="{width: (this.topData.有功.value/this.topData.有功.maxValue?(this.topData.有功.value/this.topData.有功.maxValue)>1?1:(this.topData.有功.value/this.topData.有功.maxValue):0).toFixed(2)*100+'%'}"></span>
                  </div>
                  <span class="list-item-value">{{ this.topData.有功.value }}</span>
                </div>
                <div class="content-2nd-list-item">
                  <span class="list-item-label">{{commonLangHandler('chuneng3_reactive','无功(kVar)', getZEdata)}}</span>
                  <div class="list-item-progress" style="width: 118px">
                    <span :class="'status'+ '1'" :style="{width: (this.topData.无功.value/this.topData.无功.maxValue?(this.topData.无功.value/this.topData.无功.maxValue)>1?1:(this.topData.无功.value/this.topData.无功.maxValue):0).toFixed(2)*100+'%'}"></span>
                  </div>
                  <span class="list-item-value">{{ this.topData.无功.value}}</span>
                </div>
                <div class="content-2nd-list-item">
                  <span class="list-item-label">{{commonLangHandler('chuneng3_factor','功率因数', getZEdata)}}</span>
                  <div class="list-item-progress" style="width: 118px">
                    <span :class="'status'+ '1'" :style="{width: (this.topData.功率因数.value/this.topData.功率因数.maxValue?(this.topData.功率因数.value/this.topData.功率因数.maxValue)>1?1:(this.topData.功率因数.value/this.topData.功率因数.maxValue):0).toFixed(2)*100+'%'}"></span>
                  </div>
                  <span class="list-item-value">{{ this.topData.功率因数.value }}</span>
                </div>
              </div>
              <div class="content-2nd-list">
                <div style="margin-left: 8px;">{{commonLangHandler('chuneng3_direct','直流电', getZEdata)}}</div>
                <div class="content-2nd-list-item">
                  <span class="list-item-label">{{commonLangHandler('chuneng6_voltage','电压(V)', getZEdata)}}</span>
                  <div class="list-item-progress" style="width: 118px">
                    <span :class="'status'+ '1'" :style="{width: (this.topData.电压.value/this.topData.电压.maxValue?(this.topData.电压.value/this.topData.电压.maxValue)>1?1:(this.topData.电压.value/this.topData.电压.maxValue):0).toFixed(2)*100+'%'}"></span>
                  </div>
                  <span class="list-item-value">{{ this.topData.电压.value }}</span>
                </div>
                <div class="content-2nd-list-item">
                  <span class="list-item-label">{{commonLangHandler('photovoltaicBox14_current','电流(A)', getZEdata)}}</span>
                  <div class="list-item-progress" style="width: 118px">
                    <span :class="'status'+ '1'" :style="{width: (this.topData.电流.value/this.topData.电流.maxValue?(this.topData.电流.value/this.topData.电流.maxValue)>1?1:(this.topData.电流.value/this.topData.电流.maxValue):0).toFixed(2)*100+'%'}"></span>
                  </div>
                  <span class="list-item-value">{{ this.topData.电流.value }}</span>
                </div>
                <div class="content-2nd-list-item">
                  <span class="list-item-label">{{commonLangHandler('chuneng6_power','功率(kW)', getZEdata)}}</span>
                  <div class="list-item-progress" style="width: 118px">
                    <span :class="'status'+ '1'" :style="{width: (this.topData.功率.value/this.topData.功率.maxValue?(this.topData.功率.value/this.topData.功率.maxValue)>1?1:(this.topData.功率.value/this.topData.功率.maxValue):0).toFixed(2)*100+'%'}"></span>
                  </div>
                  <span class="list-item-value">{{ this.topData.功率.value }}</span>
                </div>
              </div>
            </div>
          </div>
        </dashCard>
        <a-modal v-if="!isModal" class="Amodal" v-model="option.visible" :closable="false" :footer="null" :keyboard="false" width="100%" :dialog-style="{ top: '0px' }">
            <div style="height:calc(100vh)">
                <photovoltaicBox11  :option="option" :isModal="true" :orginSearchObj="searchObj"/>
            </div>
        </a-modal>
    </div>
    </template>
    <script>
    import Img005 from "@/assets/imgs/add/product5.png"
    export default {
        name: 'photovoltaicBox11',
        components: {
            dashCard: () => import('@/component/dashCard.vue'),
        },
        data() {
            return {
              topData:{
                "运行状态" : "",
                "工作模式" : "",
                "设备温度" : "",
                "工作效率" : "",
                "电压" : {
                  "maxValue": "",
                  "value": ""
                },
                "电流" : {
                  "maxValue": "",
                  "value": ""
                },
                "功率" : {
                  "maxValue": "",
                  "value": ""
                },
                "有功" : {
                  "maxValue": "",
                  "value": ""
                },
                "无功" : {
                  "maxValue": "",
                  "value": ""
                },
                "功率因数" : {
                  "maxValue": "",
                  "value": ""
                },
              },
              bottomList:[],
                operateWidth: 64, //操作列宽度
                isLoading: false,//数据加载中

                itemList: [
                    {
                        code: '逆变器编号：1AA1',
                        name:'逆变器编号：1AA1'
                    },
                ],
                searchObj: {
                    ITEM: '',//选中的项目
                },
                list1:{},
                chartOption1: {},
                detailInfo: {},
                colorList: ["#1191E6","#7ED6C6","#1E6119","#160C9C"],
                optionsData:[],
            }
        },
        props: {
            gridOption: {
                type: Object,
                default: function() {
                    return { w: 6, h: 6 }
                }
            },
            option: {
                type: Object,
                default: function() {
                    return {
                        visible: false
                    }
                }
            },
            isModal: {
                type: Boolean,
                default: function() {
                    return false
                }
            },
            orginSearchObj: {
                type: Object,
                default: function() {
                    return {}
                }
            }
        },
      watch: {
        gridOption: {
          handler: function(newVal,oldVal) {
            this.$nextTick(_=>{
              this.getOperateWidth()
              var dom = this.$refs['chart1']
              if(!!dom) {
                dom.handleWindowResize()
              }
            })
          },
          deep: true,
          immediate: true
        },
        "option.visible": {//全屏化时执行
            handler: function(newVal,oldVal) {
                if(newVal) {
                    if(this.isModal) {
                        this.searchObj = this.orginSearchObj
                        this.getData()
                    }
                }
            },
            deep: true,
            immediate: true
        },
        "currentSelectDeptInfo.CODE": {
          handler: function(newVal,oldVal) {
            if(oldVal) {
              this.getList()
            }
          },
          deep: true,
          immediate: true
        }
      },
        computed: {
          // 当前Dept信息
          currentSelectDeptInfo() {
            return this.$store.state.currentSelectDeptInfo
          }
        },
        methods: {
          getList(){
            this.$axios({
              url:  Config.dev_url + "/api-apps-v2/api/v2/equipment/assetlist",
              method:"post",
              data:{
                deptCode: this.currentSelectDeptInfo.CODE,
                type:"储能PCS"
              }
            }).then(res=>{
              console.log(res);
              let arr =[]
              res.data.data.forEach(item=>{
              let obj = {
                value:item.assetNumber,
                label: item.assetName
              }
              arr.push(obj)
              this.optionsData.push(obj)
              if (this.optionsData.length !== 0) {
                this.searchObj.ITEM = this.optionsData[0].value
              }
            })
            this.getData()
            })
          },
          transformSearch: function () {
            var TYPE = this.searchObj.TYPE
            var dateStr = this.searchObj.VALUE.format('YYYY-MM-DD')
            var VALUE = this.$moment(dateStr);
            var monthVALUE = this.$moment(dateStr).startOf('month');
            var obj = {
              "按天": {
                type: "今日",
                startDate: VALUE.format('YYYY-MM-DD 00:00:00'),
                endDate: VALUE.format('YYYY-MM-DD 23:59:59'),
              },
              "按周": {
                type: "本周",
                startDate: VALUE.startOf('week').format('YYYY-MM-DD 00:00:00'),
                endDate: VALUE.endOf('week').format('YYYY-MM-DD 23:59:59'),
              },
              "按月": {
                type: "本月",
                startDate: monthVALUE.startOf('month').format('YYYY-MM-DD 00:00:00'),
                endDate: monthVALUE.endOf('month').format('YYYY-MM-DD 23:59:59'),
              },
              "按年": {
                type: "全年",
                startDate: VALUE.startOf('year').format('YYYY-MM-DD 00:00:00'),
                endDate: VALUE.endOf('year').format('YYYY-MM-DD 23:59:59'),
              }
            }
            return obj[TYPE]
          },
            // 全屏切换
            changeScreen() {
                this.option.visible = !this.isModal
                if(!this.option.visible) {
                    this.getData()
                }
            },
            // 右侧操作列宽度
            getOperateWidth () {
                if(!this.$el.querySelector) {
                    return
                }
                var dom = this.$el.querySelector(".card-head-operate")
                if(!!dom) {
                    var width = dom.clientWidth
                    this.operateWidth = width > 0 ? width + 12 : width
                }
            },
            // 数字转为千位分隔符表示
            toThousandsSeparator: function(value) {
                if(!value) return 0
                // 获取整数部分
                const intPart = Math.trunc(value)
                // 整数部分处理，增加,
                const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
                // 预定义小数部分
                let floatPart = ''
                // 将数值截取为小数部分和整数部分
                const valueArray = value.toString().split('.')
                if (valueArray.length === 2) { // 有小数部分
                    floatPart = valueArray[1].toString() // 取得小数部分
                    return intPartFormat + '.' + floatPart
                }
                return intPartFormat + floatPart
            },

    // 切换项目
    handleChange(value) {
      this.getData()
    },
    //每月报警预警数
    getData() {
      this.isLoading = true
      this.$axios({
        url:Config.dev_url + "/api-apps-v2/api/v2/battery/pcs/realtimevalue",
        method:"post",
        data:{
          assetNumber : this.searchObj.ITEM
        }
      }).then(res=>{
        // this.detailInfo = {
        //   src: Img005,
        //   value1:'运行',
        //   value2:'并网',
        //   value3: obj["总运行时间"],
        //   value4:obj["总发电量"],

        //   list1: [
        //       ...result.slice(0,6)
        //   ],
        //   list2: [
        //     ...result.slice(6,-1)
        //   ],
        // }
        // //console.log(res.data.data);
        var list1 = res.data.data;

        for (let key in list1) {
          let obj=list1[key]
          if(key=="operationState"){
            this.topData.运行状态 = obj.value
          }
          else if(key=="deviceTemperature"){
            this.topData.设备温度 = obj.value
          }else if(key=="workingMode"){
            this.topData.工作模式 = obj.value
          }
          else if(key=="workingEfficiency"){
            this.topData.工作效率 = obj.value
          }
          else if(key=="dcVoltages"){
            this.topData.电压 = obj
          }
          else if(key=="dcCurrent"){
            this.topData.电流 = obj
          }
          else if(key=="dcTotalPower"){
            this.topData.功率 = obj
          }
          else if(key=="activePower"){
            this.topData.有功 = obj
          }
          else if(key=="reactivePower"){
            this.topData.无功 = obj
          }
          else if(key=="powerFactor"){
            this.topData.功率因数 = obj
          }
        }

        // list1.forEach(obj=>{
        //   debugger;

        // })
        //console.log(this.topData)
      }).catch((wrong)=>{ }).finally(()=>{
        this.isLoading = false
      })

    },
    //   getData0() {
    //   this.detailInfo = {
    //     src: Img005,
    //     value1:'运行',
    //     value2:'并网',
    //     value3:44,
    //     value4:102,
    //     list1: [
    //       {
    //         name: "A相电流(A)",
    //         status:1,
    //         count: 30,
    //         num: 20,
    //       },
    //       {
    //         name: "B相电流(A)",
    //         status:3,
    //         count: 30,
    //         num: 20,
    //       },
    //       {
    //         name: "C相电流(A)",
    //         status:3,
    //         count: 30,
    //         num: 20,
    //       },
    //       {
    //         name: "额定输出功率(kW)",
    //         status:3,
    //         count: 20,
    //         num: 10,
    //       },
    //       {
    //         name: "电网频率(Hz)",
    //         status:2,
    //         count: 100,
    //         num: 50,
    //       },
    //       {
    //         name: "设备利用率(%)",
    //         status:2,
    //         count: 30,
    //         num: 20,
    //       },
    //       {
    //         name: "总并网运行时间(h)",
    //         status:1,
    //         count: 1500,
    //         num: 780,
    //       },
    //     ],
    //     // list2: [
    //     //   {
    //     //     name: "A相电压(V)",
    //     //     status:1,
    //     //     count: 800,
    //     //     num: 500,
    //     //   },
    //     //   {
    //     //     name: "B相电压(V)",
    //     //     status:1,
    //     //     count: 800,
    //     //     num: 500,
    //     //   },
    //     //   {
    //     //     name: "C相电压(V)",
    //     //     status:1,
    //     //     count: 800,
    //     //     num: 500,
    //     //   },
    //     //   {
    //     //     name: "有功功率(kW)",
    //     //     status:1,
    //     //     count: 800,
    //     //     num: 500,
    //     //   },
    //     //   {
    //     //     name: "等价发电时间(h)",
    //     //     status:1,
    //     //     count: 3000,
    //     //     num: 2618,
    //     //   },
    //     //   {
    //     //     name: "逆变器效率(%)",
    //     //     status:1,
    //     //     count: 100,
    //     //     num: 91,
    //     //   },
    //     //   {
    //     //     name: "累计发电量(kWh)",
    //     //     status:1,
    //     //     count: 2000,
    //     //     num: 1780,
    //     //   },
    //     // ],
    //   }
    // },

  },
  mounted() {
    // this.getData0()
    this.getOperateWidth()
    if(!this.option.visible) {
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>
.photovoltaicBox11 {
  .content-warp {
    display: flex;
    height: inherit;
    flex-direction: column;
    .content-1st {
      display: flex;
      height: 279px;
      justify-content: space-between;
      .product-pre1 {
        width: 35%;
        height: inherit;
        margin-right: 50px;
        margin-left: 30px;
        margin-top: 20px;
        display: flex;
        justify-content: center;
        img {
          width: 100%;
          height: auto;
        }
      }
      .content-1st-right {
        flex: 1;
        display: flex;
        margin-top: 20px;
        flex-direction: column;
        .content-1st-right-row {
          display: flex;
          justify-content: flex-start;
          &:nth-child(1) {
            margin-bottom: 50px;
          }
          div {
            display: flex;
            flex-direction: column;
            width: 132px;
            height: 78px;
            font-size: 16px;
            font-weight: 400;
            color: #595959;
            line-height: 24px;
            span {
              margin-top: 6px;
              font-size: 26px;
              font-weight: 500;
              color: #1F1F1F;
              line-height: 48px;
              font {
                font-size: 12px;
                font-weight: 400;
                color: #9f9f9f;
                line-height: 20px;
                margin-left: 6px;
              }

            }


          }
          .color1 {
            color: #3366FF;
          }
          .color2 {
            color: #51A34B;
          }
        }
      }

    }
    .content-2nd {
      display: flex;
      justify-content: flex-start;
      .content-2nd-list {
        flex: 1;
        .content-2nd-list-item {
          height: 40px;
          box-sizing: border-box;
          padding: 10px 8px;
          display: flex;
          align-items: center;
          .list-item-label {
            width: 85px;
            font-size: 14px;
            font-weight: 400;
            color: #1f1f1f;
            line-height: 20px;

          }
          .list-item-progress {
            margin-right: 28px;
            //flex: 1;
            height: 7px;
            background: #dbdbdb;
            span {
              display: block;
              height: inherit;
              width: 0%;
            }
          }
          .list-item-value {
            font-size: 14px;
            font-weight: 400;
            color: #1f1f1f;
            line-height: 20px;
          }

        }
        .status1 {
          background: #25B131;
        }
        .status2 {
          background: #FF7B10;
        }
        .status3 {
          background: #F1404F;
        }
      }

    }
  }
}
</style>
